@import "./variables.scss";

.headerReplace {
  height: 180px;
  background: $secondaryColor;

  header {
    width: 100vw;
    height: 150px;
    background-color: $secondaryColor;
    padding-top: 30px;
    padding-bottom: 30px;
    position: fixed;
    top: 0;
    z-index: 999;
    transition: 1s;

    .headerCont {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      pointer-events: none;

      img {
        width: 160px;
        margin: auto;
        pointer-events: all;
      }
      button.menuButton {
        border: none;
        background: transparent;
        outline: none;
        color: $txtWhite;
        cursor: pointer;
        pointer-events: all;
      }
      .fa-caret-down {
        transition: 0.4s;
        pointer-events: all;
      }
      .fa-caret-down.caret-turn {
        transform: rotate(180deg);
        pointer-events: all;
      }
      .none {
        display: none;
      }

      ul {
        display: flex;
        justify-content: center;
        align-items: left;
        pointer-events: all;

        .current {
          font-weight: 800;
        }
        li {
          list-style: none;

          a {
            color: $txtWhite;
            text-decoration: none;
            cursor: pointer;
          }

          ul {
            display: flex;
            justify-content: center;
            align-items: left;
            pointer-events: all;
            flex-direction: row;
            margin: 0 0 0 0;
            padding: 0;

            @include w800 {
              margin: 0 0 0 -10px;
            }
            li {
              @include w800 {
                margin: 0 0 0 10px;
              }

              img {
                width: 25px;
              }
            }
          }
        }
      }
      ul.mobileMenu {
        opacity: 0;
        transition: 0.4s;
        flex-direction: column;
        translate: 0 -50px;
        cursor: auto;
        padding: 10px 30px 30px 30px;
        pointer-events: none;

        li {
          padding-bottom: 10px;
        }
      }
      ul.mobileMenu.open {
        flex-direction: column;
        background: $secondaryColor;
        padding: 10px 30px 30px 30px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        opacity: 1;
        transition: 0.4s;
        translate: 0 -10px;
        pointer-events: all;
      }

      ul.desktopMenu {
        flex-direction: row;
        width: 95%;
        margin-top: -5px;
        li {
          margin-left: 15px;
        }
      }
    }
  }
}

.introduction {
  display: flex;
  justify-content: center;
  text-align: center;
  background-image: url("../img/bg1.gif");
  background-attachment: fixed;
  background-position: center;
  pointer-events: none;
  height: 400px;
  @supports (-webkit-touch-callout: none) {
    background-attachment: unset;
  }
  h1 {
    color: $txtWhite;
    font-size: 50px;
    font-weight: 400;
    text-align: center;
    position: relative;
    top: 150px;
    text-transform: capitalize;
  }
}

.introductionXL {
  height: 500px;
}

.home {
  .homeCont {
    .mainHome {
      padding: 50px 10px 50px 10px;
      min-height: 500px;
      text-align: center;
      .title {
        h2 {
          font-size: 36px;
          @include mobile {
            font-size: 30px;
          }
        }
        h3 {
          font-weight: 200;
          font-size: 16px;
          margin-bottom: 50px;
          @include mobile {
            font-size: 12px;
          }
        }
      }
      .keen-slider {
        width: 95vw;
        margin: auto;
        position: relative;

        .nav.default-nav {
          background: $grey05;
        }

        img {
          //   float: left;
          width: 40%;
          display: inline-block;
          vertical-align: middle;
          margin-right: 20px;
          @include w700 {
            display: none;
          }
        }
        .rightCard {
          text-align: left;
          //   float: right;
          width: 35%;
          height: 100%;
          display: inline-block;
          vertical-align: middle;
          margin-left: 100px;
          @include w700 {
            text-align: center;
            margin: auto;
            width: 60%;
          }
          @include mobile {
            width: 90%;
          }

          h2 {
            font-family: "Roboto", Helvetica, Arial, Lucida, sans-serif;
            font-weight: 100;
            font-size: 50px;
            opacity: 0.8;
            @include tablet {
              font-size: 35px;
            }
          }

          p {
            margin-bottom: 30px;
            color: #333;
            opacity: 0.8;
            @include tablet {
              font-size: 13px;
            }
          }

          button {
            width: 150px;
            height: 50px;
            background-color: $secondaryColor;
            border: none;
            outline: none;
            color: $txtWhite;
            border-radius: 10px;
            transition: 0.4s;
            cursor: pointer;
            font-weight: 300;
            color: $txtWhite;

            @include tablet {
              width: 120px;
              height: 40px;
              font-size: 15px;
              border-radius: 5px;
            }

            &:hover {
              scale: 0.95;
            }
          }
        }
      }
      .dot {
        height: 8px;
        width: 8px;
        background-color: #bbb;
        border: none;
        border-radius: 50%;
        margin: 20px 10px 0 0;
        cursor: pointer;
        padding: 0;
      }
      .active {
        background: #5a5a5a;
        cursor: default;
      }
    }
  }
}
.main {
  margin: 150px 30px 150px 20%;
  max-width: 600px;

  @include mobile {
    margin: 100px 30px 100px 30px;
  }

  span.error {
    color: $grey05;
    font-size: 200px;
  }

  .blueH2 {
    font-size: 20px;
    padding-left: 30px;
    margin-top: 60px;
    margin-bottom: 20px;
    border-left: solid 7px $secondaryColor;
  }
  ul {
    margin-left: -40px;
    list-style: none;
    font-weight: 200;
    li {
      font-size: 15px;
    }
  }
  p {
    font-weight: 200;
    font-size: 15px;
  }
  a {
    text-decoration: none;
    color: $secondaryColor;
    &:hover {
      text-decoration: underline;
    }
  }

  p.thick {
    font-weight: 500;
    font-size: 18px;
  }
}

.main.projecten {
  margin: 150px auto 150px auto;
  max-width: fit-content;
  .grid {
    display: grid;
    gap: 25px;
    grid-template-columns: repeat(2, 1fr);
    max-width: 439px;

    @include w500 {
      grid-template-columns: repeat(1, 1fr);
    }

    .items {
      position: relative;

      @include w500 {
        margin: auto;
      }

      img {
        z-index: 2;
        position: relative;
        border: 3px solid #e5e5e5;
        transition: 0.7s;
        min-width: 207px;
        height: 136px;
        cursor: pointer;

        &:hover,
        &:active {
          translate: 0 -10px;
        }
      }

      .shadow {
        display: block;
        background: url(../img/item-bottom-shadow.png) no-repeat;
        width: 215px;
        height: 20px;
        position: absolute;
        bottom: -13px;
        left: -2px;
        opacity: 0.8;
      }
    }
  }
}

.projectenPics {
  .mainPart {
    min-height: 500px;
    background-color: $mainColor;
    padding-bottom: 150px;

    .mainContent {
      width: 100%;
      padding-top: 100px;
      color: $txtBlack;
      padding-left: 20px;

      @include mobile {
        padding-left: 0;
      }

      img {
        display: block;
        width: 400px;
        border: solid 5px #e5e5e5;
        box-shadow: 0px 0px 3px 3px rgba(135, 135, 135, 0.403);
        margin: auto;

        @include mobile {
          width: 90vw;
        }
      }

      .grid {
        display: grid;
        gap: 1rem;
      }
    }
  }
}

.footer {
  background-color: $secondaryColor;
  min-height: 760px;
  width: 100vw;
  position: relative;
  font-family: "Roboto", Helvetica, Arial, Lucida, sans-serif;
  .txtWrapper {
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: row;
    // flex-wrap: wrap;
    max-width: 860px;
    gap: 100px;
    padding: 122px 10px 0 150px;

    @include w700 {
      padding: 122px 20px 0 30px;
    }
    @include w600 {
      flex-wrap: wrap;
      padding: 122px 20px 122px 20px;
    }

    .txt {
      width: 50%;

      @include w600 {
        width: 95%;
      }

      h3,
      p,
      li,
      a,
      button {
        color: $txtWhite;
      }
      h3 {
        font-weight: 800;
        margin-bottom: 20px;
      }
      button {
        font-weight: 800;
        font-size: 20px;
        border: none;
        outline: none;
        background: transparent;
        padding: 0;
        cursor: pointer;
      }
    }
  }
  .copyright {
    position: absolute;
    bottom: 0;
    text-align: center;
    padding-bottom: 10px;
    p {
      width: 100vw;
      color: $txtWhite;
      font-size: 10px;
    }
  }
}
