$secondaryColor: #147de7;
$mainColor: #fafafa;
$txtBlack: #333;
$txtWhite: #ffff;
$grey05: #dbdbdb8e;

/* andy bell 

        /* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
  color: $txtBlack;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
/* andy bell end */

@mixin w350 {
  @media screen and (max-width: 350px) {
    @content;
  }
}
@mixin mobile {
  @media screen and (max-width: 480px) {
    @content;
  }
}
@mixin w500 {
  @media screen and (max-width: 500px) {
    @content;
  }
}
@mixin w600 {
  @media screen and (max-width: 600px) {
    @content;
  }
}
@mixin w700 {
  @media screen and (max-width: 700px) {
    @content;
  }
}
@mixin w800 {
  @media screen and (max-width: 800px) {
    @content;
  }
}
@mixin tablet {
  @media screen and (max-width: 900px) {
    @content;
  }
}
@mixin laptop {
  @media screen and (max-width: 1200px) {
    @content;
  }
}

@mixin lilHeight {
  @media screen and (max-height: 700px) {
    @content;
  }
}

body {
  font-family: "Roboto", Helvetica, Arial, Lucida, sans-serif;
  background: $mainColor;
  width: 100vw;
  overflow-x: hidden;
}
